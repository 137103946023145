.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  border-top: 1px solid #b3b3b3;
  .middle {
    p {
      color: var(--primary-black);
      font-weight: 600;
      margin-bottom: 0;
    }
  }
  .base {
    display: flex;
    gap: 10px;
    button {
      padding: 12px 25px;
      color: #4d4d4d;
      border: 0;
      font-size: 12px;
      border-radius: 30px;
      font-weight: 500;
    }
    .getSupport {
      background: #35d4ae33;
    }
    .reportBug {
      background: #ff995533;
    }
  }
}
@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    gap: 20px;
  }
}