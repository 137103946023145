@mixin fontStyle {
    color: var(--light-black);
    font-family: Montserrat;
    line-height: normal;
    font-style: normal;
}
@mixin flexCss {
    display: flex;
    justify-content: space-between;
}
.resumeScanningLeftSide {
    .job-heading {
        font-weight: 600;
        font-size: 26px;
        color: var(--darker-grey);
    }
    width: 100%;
    border-radius: 5px;
    .searchWithNew {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        .templateSearchField {
            width: 100%;
        }
        .templateSearchField .templateSearchIconSec input.form-control {
            box-shadow: 0px 5px 25px 0px var(--border-shadow);
            border: 2px solid var(--url-input-grey);
            &::placeholder {
                color: var(--light-grey);
                font-size: 16px;
                font-weight: 600;
            }
        }
    }
    .filterWork {
        display: flex;
        margin-top: 20px;
        gap: 20px;
        margin-bottom: 20px;
        .checkboxWithLabel {
            display: flex;
            cursor: pointer;
            .radioCheckLabel {
                @include fontStyle;
                font-size: 16px;
                font-weight: 500;
                margin: 0;
                position: relative;
                top: 3px;
                cursor: pointer;
            }
        }
    }
    .assessmentListContentSec {
        .assessmentListCardSec {
            padding: 0
        }
        .assessmentListContentTitle {
            @include fontStyle;
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 20px;
        }
        .assessmentListCard {
            display: flex;
            border: 1px solid var(--off-white);
            margin-bottom: 20px;
            &:hover {
                box-shadow: 0 0 10px var(--primary-green);
            }
            .assessmentListCardImg {
                background-color: #edfffb;
                padding: 15px 30px;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                flex: 1;
                h6 {
                    @include fontStyle;
                    font-size: 14px;
                    font-weight: 500;
                }
                h4 {
                    @include fontStyle;
                    font-size: 20px;
                    font-weight: 700;
                    width: max-content;
                }
            }
            .assessmentListCardDetails {
                width: 100%;
                padding: 0px 31px;
                flex: 6;
                .listCardDetailsTitleSec {
                    @include flexCss;
                    gap: 79px;
                    .listCardDetailsTitle {
                        width: 100%;
                        .listItemSec {
                            border-bottom: 1px solid var(--division-grey);
                            margin: 20px 0;
                            .listCardTitleHeading {
                                display: flex;
                                justify-content: space-between;
                                flex-wrap: wrap;
                                h3 {
                                    @include fontStyle;
                                    font-size: 22px;
                                    font-weight: 600;
                                    display: -webkit-box;
                                    -webkit-box-orient: vertical;
                                    -webkit-line-clamp: 2;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    word-break: break-word;
                                }
                                .listCopyIcon {
                                    top: -6px;
                                    left: 0px;
                                    cursor: pointer;
                                    margin: 0 10px;
                                    padding-bottom: 5px;
                                }
                                .clipBoard {
                                    display: flex;
                                    align-items: center;
                                    .assessmentCommonBtn {
                                        margin: 6px;
                                        button {
                                            padding: 4px 6px;
                                        }
                                    }
                                }
                                .shadowSvg {
                                    box-shadow: 0px 0px 20px 0px #0000001a;
                                    border-radius: 50%;
                                    margin: 0 10px;
                                }
                                p {
                                    @include fontStyle;
                                    font-size: 14px;
                                    font-weight: 500;
                                    margin-bottom: 8px;
                                }
                                .recommendationIcon {
                                    svg {
                                        background-color: var(--primary-white);
                                        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1019607843);
                                        border-radius: 50%;
                                        margin: 0 10px;
                                    }
                                }
                            }
                            .link_wrap {
                                margin: 8px 0;
                                margin-right: 10px;
                                position: relative;
                                .acc_style04 {
                                    border-radius: 3px;
                                    font-size: 14px;
                                    font-weight: 500;
                                    background: #e1f9f3;
                                    padding: 6px 8px 6px 24px;
                                    color: var(--primary-black);
                                    transition: all 0.2s ease-in;
                                    position: relative;
                                    width: fit-content;
                                    margin-bottom: 0;
                                    &::before {
                                        content: "";
                                        position: absolute;
                                        background: var(--primary-green);
                                        clip-path: polygon(100% 0%,
                                                100% 100%,
                                                50% 75%,
                                                0 100%,
                                                0 0);
                                        transition: all 0.2s ease-in;
                                        left: 6px;
                                        top: -2px;
                                        width: 12px;
                                        height: 60%;
                                    }
                                }
                            }
                            .bulletSec {
                                margin-bottom: 15px;
                                .graySkill {
                                    color: #fff;
                                    font-family: Montserrat;
                                    font-size: 12px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: normal;
                                    border-radius: 3px;
                                    background: var(--light-grey);
                                    padding: 7px 9px;
                                }
                            }
                        }
                    }
                }
                .listCardDetailsContent {
                    display: flex;
                    align-items: center;
                    gap: 100px;
                    p {
                        margin-bottom: 5px;
                    }
                }
                .listContentSkillsSec {
                    @include flexCss;
                    align-items: center;
                    gap: 79px;
                    margin-bottom: 10px;
                    .locationDiv {
                        text-transform: capitalize;
                    }
                    .listContentSkills {
                        display: flex;
                        gap: 100px;
                        width: 100%;
                        p {
                            margin-bottom: 5px;
                            font-weight: 500;
                        }
                    }
                    .decreaseGap {
                        gap: 78px;
                        h5 {
                            font-weight: 600;
                        }
                    }
                    .RightSideArrowSec {
                        display: flex;
                        align-items: center;
                        margin-top: 35px;
                        gap: 10px;
                        .starLogo {
                            width: 35px;
                            height: 35px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50%;
                            svg {
                                path {
                                    fill: var(--primary-green);
                                }
                            }
                        }
                    }
                }
            }
        }
        .totalContent {
            display: flex;
            justify-content: end;
            font-size: 12px;
            font-weight: 600;
            color: var(--darker-grey);
        }
    }
}
@media (max-width: 768px) {
    .resumeScanningLeftSide {
        .assessmentListContentSec .assessmentListCard {
            flex-direction: column;
            padding-bottom: 20px;
            .assessmentListCardImg {
                padding: 15px 25px;
            }
            .assessmentListCardDetails {
                padding: 0 20px;
                .listContentSkillsSec {
                    gap: 0px;
                    flex-direction: column;
                    .listContentSkills {
                        flex-direction: column;
                        gap: 20px;
                    }
                }
            }
        }
    }
}
@media (min-width: 768px) and (max-width: 992px) {
    .resumeScanningLeftSide {
        .assessmentListContentSec {
            .assessmentListCard {
                flex-direction: column;
            }
        }
    }
}
@media (min-width: 993px) and (max-width: 1130px) {
    .resumeScanningLeftSide .assessmentListContentSec .assessmentListCard .assessmentListCardDetails {
        width: 80%;
        padding: 0px 31px;
        flex: 6 1;
    }
    .resumeScanningLeftSide .assessmentListContentSec .assessmentListCard .assessmentListCardDetails .listContentSkillsSec {
        flex-direction: column;
        gap: 0px !important;
        align-items: flex-end;
    }
}
@media (max-width: 935px) and (min-width: 769px) {
    .resumeScanningLeftSide .assessmentListContentSec .assessmentListCard .assessmentListCardDetails .listContentSkillsSec {
        flex-direction: column;
        gap: 0px !important;
        align-items: flex-end;
    }
}
@media (max-width: 580px) {
    .resumeScanningLeftSide .assessmentListContentSec .assessmentListCard .assessmentListCardDetails .listCardDetailsTitleSec .listCardDetailsTitle .listItemSec .bulletSec {
        margin-top: 10px;
    }
}