.ticketWrapper {
  .card {
    // border: 1px solid #efefef;
    border: none;
    display: flex;
    margin-bottom: 20px;
    border-radius: 0%;

    .form-check {
      text-align: left;
    }

    .formLabel {
      color: #5a5a5a;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding-bottom: 15px;
    }

    .formHeading {
      font-size: 24px;
      font-weight: 600;
      color: #5a5a5a;
      margin-bottom: 15px;
    }
  }
}
.newTicketForm {
  box-shadow: 0px 4px 10px 0px #0000001a;
  padding: 60px;
  border-radius: 10px;
  select,
  input {
    &:focus {
      box-shadow: none;
      border-color: #bebebe;
    }
  }
  .attachTicketImg {
    position: relative;
    input {
      opacity: 0;
      position: absolute;
    }
    span {
      border: 1px solid #b3b3b3;
      padding: 8px 12px;
      border-radius: 3px;
      color: #666666;
      font-size: 14px;
      svg {
        margin-left: 5px;
      }
    }
  }
  button[type="submit"]{
    background-color: #35D4AE;
    border: 0;
    padding: 10px 12px;
    width: 100%;
    margin-top: 40px;
    text-transform: uppercase;
    font-weight: 600;
  &:focus{
    background-color: #35D4AE;
  }
  }
}
.newTicketsSec {
  // display: flex;
  // justify-content: space-between;
  // margin-bottom: 10px;
  // border: 1px solid #d6fff1;
  // padding: 10px 15px;
  box-shadow: 0px 3px 5px 0px #80a99e;
  padding: 10px 15px;

  h6 {
    color: #5a5a5a;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .newTicketsContent {
    span {
      background-color: orangered;
      text-transform: uppercase;
      margin-left: 7px;
      padding: 2px 10px;
      border-radius: 3px;
      border-radius: 3px;
      font-size: 12px;
      color: #fff;
    }
  }

  p.ticketDecription {
    font-size: 13px;
  }
}
