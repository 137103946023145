@mixin flexCss {
  display: flex;
  justify-content: space-between;
}
@mixin fontStyle {
  color: #4d4d4d;
  font-family: Montserrat;
  line-height: normal;
  font-style: normal;
}
@media(max-width:1062px) {
  .ticketListWrapper .filterClicked .typeSelectSec {
    flex-direction: column;
  }
  .ticketListWrapper .filterClicked .typeSelectSec .dropdowns-tickets {
    width: 50%;
  }
  .ticketListWrapper .filterClicked .typeSelectSec .typeSelectDateSec {
    flex-wrap: wrap;
  }
}
@media(max-width:600px) {
  .ticketListWrapper .filterClicked .typeSelectSec .dropdowns-tickets {
    width: 100%;
  }
}
@media(max-width:360px) {
  .ticketListWrapper .filterClicked .typeSelectSec .dropdowns-tickets {
    flex-direction: column;
  }
}
@media(max-width:450px) {
  .ticketListWrapper .CreatedAssessmentListTitle {
    align-items: flex-start !important;
    flex-direction: column !important;
    gap: 10px;
  }
}