  @mixin flexCss {
      display: flex;
      justify-content: space-between;
  }
  .totalContentDiv {
      .totalContent {
          display: flex;
          justify-content: end;
          font-size: 12px;
          font-weight: 600;
          color: var(--darker-grey);
          margin-right: 10px;
      }
  }
  .reportedQuestionsList {
      .assessResponseHead {
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
          margin-bottom: 25px;
          .assessResponseHeadContent {
              display: flex;
              justify-content: space-between;
              align-items: baseline;
              width: 100%;
              flex-wrap: wrap;
              h3 {
                  font-size: 24px;
                  font-weight: 600;
                  color: var(--light-black);
                  line-height: 29px;
              }
          }
          p {
              font-size: 16px;
              font-weight: 500;
              line-height: 20px;
              margin: 0;
              span {
                  font-size: 16px;
                  font-weight: 700;
                  line-height: 20px;
              }
          }
      }
      .responseReviewQuestion {
          .assessResponseList {
              position: relative;
              @include flexCss;
              border: 1px solid #efefef;
              border-radius: 5px;
              padding: 25px;
              gap: 10px;
              margin-bottom: 30px;
              &:hover {
                  border: 1px solid var(--primary-green);
                  box-shadow: 0 0 10px var(--primary-green);
                  cursor: pointer;
              }
              .assessQuestion {
                  // border-right: 1px solid var(--disable-light-grey);
                  flex: 1;
                  margin-bottom: 0;
                  padding-bottom: 0;
                  padding-right: 10px;
                  display: flex;
                  flex-direction: column;
                  html[dir="rtl"] & {
                      border: none;
                  }
                  .questionText {
                      margin-top: 10px;
                      p {
                          font-family: Montserrat;
                          font-size: 18px;
                          font-weight: 600;
                          color: var(--light-black);
                      }
                  }
                  .assessType {
                      display: flex;
                      gap: 50px;
                      margin-top: 0;
                      h6 {
                          font-size: 16px;
                          font-weight: 500;
                          color: var(--darker-grey);
                          padding: 10px 18px;
                          margin-bottom: 0;
                          padding-bottom: 0;
                          span {
                              font-size: 16px;
                              font-weight: 600;
                              margin-left: 8px;
                          }
                      }
                  }
              }
          }
          .questionDetails {
              .companyAssessdetail {
                  .companyAssessmentDiv {
                      display: flex;
                      gap: 2px;
                      font-family: Montserrat;
                      justify-content: space-between;
                      .firstSecTitle {
                          color: var(--light-black);
                          font-size: 14px;
                          font-weight: 400;
                      }
                      .firstSecValue {
                          font-size: 18px;
                          font-weight: 700;
                          color: var(--light-black);
                      }
                      .reportedBy {
                          font-family: Montserrat;
                          font-size: 13px;
                          font-weight: 500;
                          color: var(--light-black);
                      }
                  }
              }
              .typeStatus {
                  display: flex;
                  gap: 10px;
                  margin-bottom: 10px;
                  .bulletSec {
                      margin-top: 4px;
                      .questionSkillName {
                          color: var(--primary-white);
                          font-family: Montserrat;
                          font-size: 12px;
                          font-weight: 600;
                          border-radius: 3px;
                          background: var(--primary-green);
                          padding: 9px 7px;
                          line-height: 12px;
                          height: fit-content;
                      }
                  }
                  p {
                      margin-bottom: 0;
                      gap: 5px;
                      .answerType {
                          font-size: 16px;
                      }
                  }
                  .answerType {
                      font-weight: 600;
                      color: var(--darker-grey);
                      font-size: 20px;
                      .reportedAiIcon {
                          margin-left: 8px;
                      }
                  }
                  .borderRight {
                      border: 1px solid var(--light-black);
                      height: 15px;
                      margin-top: 6px;
                  }
                  .answerStatus {
                      font-weight: 600;
                      color: var(--light-black);
                      text-transform: capitalize;
                  }
              }
          }
          .reportedText-sec {
              background-color: #F6FFFD;
              padding: 15px;
              .reportedTextHeading {
                  font-family: Montserrat;
                  font-size: 14px;
                  font-weight: 600;
                  color: var(--light-black);
              }
              .reportedText {
                  font-family: Montserrat;
                  font-size: 14px;
                  font-weight: 500;
                  color: var(--darker-grey);
                  margin-right: 10px;
                  word-break: break-all;
              }
          }
      }
      .filtersSec {
          .arrowBtnSec {
              display: flex;
              align-items: center;
              gap: 15px;
              margin-top: 30px;
              p {
                  margin: 0;
                  font-family: Montserrat;
                  font-size: 16px;
                  font-weight: 600;
                  color: #4d4d4d;
              }
              .scoreBtn {
                  padding: 8px 25px !important;
                  width: fit-content;
                  margin: 0;
                  background-color: #fff;
                  border: 1px solid #b3b3b3;
                  border-radius: 5px;
                  margin-bottom: 0;
              }
              .borderGreen {
                  border-color: var(--primary-green);
              }
              .borderGrey {
                  border-color: #b3b3b3;
              }
          }
      }
  }
  @media (max-width:850px) {
      .reportedQuestionsList .responseReviewQuestion .questionDetails .companyAssessdetail .companyAssessmentDiv {
          flex-direction: column;
          gap: 10px;
          .right-content {
              width: 100% !important;
          }
          .left-content {
              flex-direction: column-reverse !important;
              align-items: flex-start !important;
          }
      }
  }