.logo-link {
  display: flex;
  gap: 50px;
  .link {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    flex-wrap: wrap;
  }
  .nav-link {
    color: #5a5a5a;
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;
  }
}
.profileIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  color: #fff;
  background-color: #35d4ae;
  cursor: pointer;
  .profileFirstLetter {
    margin-top: 5px;
    text-transform: capitalize;
  }
}
.nav-item {
  .active {
    .activeCreateBtn {
      background-color: var(--light-green) !important;
      font-weight: 600;
    }
  }
  .lightBtn {
    &:hover {
      background-color: var(--light-green) !important;
    }
  }
  .addBottomBorder {
    padding-bottom: 4px;
    padding-top: 4px;
    border-bottom: 1px solid var(--primary-green);
    // font-weight: 600;
  }
}
.navbar {
  .navbar-toggler {
    border: 0 !important;
    &:focus {
      box-shadow: none !important;
    }
  }
}
@media (max-width: 768px) {
  .navbar {
    padding-top: 20px !important;
    .logo-link {
      gap: unset;
      .navbar-collapse {
        .link {
          margin: 20px auto;
        }
        .profileIconSec {
          width: 100%;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
@media(max-width:991px) {
  .profileIconSec {
    display: flex;
    justify-content: flex-end;
  }
}