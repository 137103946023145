.userActive {
  height: 100vh;
  overflow: hidden;
  .userSec {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(53, 212, 174, 0.8);
    position: absolute;
    top: 0;
    overflow: hidden;
    .userContent {
      width: 35%;
      border-radius: 10px;
      background: #d1fff4;
      padding: 50px 76px;
      .errorMessage {
        margin-top: -15px;
        margin-bottom: 20px;
      }
      .userHeading {
        font-size: 24px;
        font-weight: 600;
        line-height: 29.26px;
        text-align: center;
        color: #5a5a5a;
        margin-bottom: 45px;
      }
      .passwordSec {
        position: relative;
        button {
          position: absolute;
          right: 20px;
          top: 11px;
          border: 0;
          font-size: 11px;
          background-color: #35d4ae;
          padding: 8px 12px;
          color: #fff;
          font-weight: 600;
          border-radius: 4px;
        }
      }
      input {
        width: 100%;
        border-radius: 4px;
        border: 1px solid #efefef;
        background: #fff;
        padding: 16px 20px;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 20px;
        &:focus {
          outline: 0;
          box-shadow: none;
        }
        &::placeholder {
          color: #b3b3b3;
        }
      }
      .submitBtn {
        color: #fff;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        border-radius: 4px;
        background: #35d4ae;
        width: 100%;
        padding: 15px;
        border: 0;
      }
    }
  }
}
@media (max-width: 768px) {
  .userActive {
    .userSec {
      .userContent {
        width: 100%;
        padding: 50px;
        margin: 15px;
      }
    }
  }
  .imageCapture {
    position: relative;
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  .userActive {
    .userSec {
      .userContent {
        width: 45%;
        padding: 50px;
        margin: 15px;
      }
    }
  }
}
