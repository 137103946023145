@mixin flexCss {
    display: flex;
    justify-content: space-between;
}
@mixin fontStyle {
    color: #4d4d4d;
    font-family: Montserrat;
    line-height: normal;
    font-style: normal;
}
.viewTicketsSec {
    .ticketContent {
        h6 {
            font-size: 14px;
            color: var(--light-black);
            font-weight: 500;
            font-family: Montserrat;
        }
        p {
            font-size: 16px;
            color: var(--light-black);
            font-weight: 600;
            font-family: Montserrat;
        }
    }
    .listItemSec {
        margin: 20px 0;
        margin-right: 20px;
        .bulletSec {
            h5 {
                font-size: 18px;
                font-weight: 600;
                line-height: 21.94px;
                color: #35d4ae;
            }
            h6 {
                font-size: 14px;
                font-weight: 500;
                color: #797979;
                line-height: 17.07px;
            }
        }
        .ListCardTitleHeading {
            .listinIconDiv {
                svg {
                    width: 70px;
                    height: 70px;
                }
            }
            display: flex;
            justify-content: space-between;
            gap: 130px;
            h3 {
                @include fontStyle;
                font-size: 22px;
                font-weight: 600;
                // ellipsis code
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-word;
            }
            p {
                color: #747474 !important;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                margin: 10px 0;
            }
            .listCopyIcon {
                position: relative;
                top: -6px;
                left: 0px;
                margin: 0 10px;
            }
            .d-flex {
                width: 100%;
                align-items: center;
                .assessmentCommonBtn {
                    margin: 6px;
                    button {
                        padding: 4px 6px;
                    }
                }
            }
            .shadowSvg {
                box-shadow: 0px 0px 20px 0px #0000001a;
                border-radius: 50%;
                margin: 0 10px;
            }
        }
    }
    .card {
        border: 1px solid #efefef;
        margin-bottom: 20px;
        border-radius: 0%;
        &:hover {
            box-shadow: 0 0 10px #35d4ae;
        }
        .card-body {
            padding: 0;
            .customSelectType .selected-option {
                background-color: var(--primary-white);
            }
            .ticketBg {
                height: 100%;
                background-color: #edfffb;
                display: flex;
                flex: 1 1;
                flex-direction: column;
                justify-content: center;
                padding: 15px 30px;
                h5 {
                    color: #4d4d4d;
                    font-family: Montserrat;
                    font-size: 20px;
                    font-weight: 500;
                }
                h6 {
                    color: #4d4d4d;
                    font-family: Montserrat;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
                .profileSec {
                    display: flex;
                    text-align: center;
                    flex-direction: column;
                    width: 100%;
                    align-items: center;
                    padding: 20px;
                    svg {
                        width: 100px;
                        height: 100px;
                    }
                    h4 {
                        color: #4d4d4d;
                        font-family: Montserrat;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        width: -webkit-max-content;
                        width: max-content;
                    }
                    h6 {
                        color: #4d4d4d;
                        font-family: Montserrat;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }
                }
                .statusDropDown {
                    .selectField {
                        .customSelectType {
                            .selected-option {
                                color: var(--light-black) !important;
                            }
                        }
                    }
                }
                .typeHead {
                    .selectField {
                        .customSelectType {
                            .selected-option {
                                color: var(--light-grey);
                                font-family: Montserrat;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                            }
                            .options {
                                li {
                                    color: var(--light-black);
                                    font-family: Montserrat;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 500;
                                }
                            }
                        }
                    }
                    label {
                        display: none !important;
                    }
                }
            }
            .ticketBodyBg {
                height: 100%;
                display: flex;
                flex: 1 1;
                flex-direction: column;
                justify-content: center;
                padding: 15px 30px;
                h5 {
                    color: #4d4d4d;
                    font-family: Montserrat;
                    font-size: 20px;
                    font-weight: 500;
                }
                .profileSec {
                    display: flex;
                    text-align: center;
                    flex-direction: column;
                    width: 100%;
                    align-items: center;
                    padding: 20px;
                    svg {
                        width: 100px;
                        height: 100px;
                    }
                    h4 {
                        color: #4d4d4d;
                        font-family: Montserrat;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        width: -webkit-max-content;
                        width: max-content;
                    }
                    h6 {
                        color: #4d4d4d;
                        font-family: Montserrat;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }
                }
                .messageSendBtn {
                    margin-top: 10px;
                    display: flex;
                    justify-content: flex-end;
                }
                .settingTextArea {
                    .ql-container {
                        max-height: none;
                        height: 270px;
                    }
                }
                .textEditorMsg {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .limitMsg {
                        font-size: 12px;
                        font-weight: 600;
                        margin-top: 5px;
                        position: relative;
                    }
                }
                .ticketHead {
                    .attachTicketImg {
                        .ChooseImageIcon {
                            span {
                                font-size: 14px;
                                color: var(--light-black);
                                font-weight: 500;
                                font-family: Montserrat;
                            }
                        }
                    }
                    .conversation {
                        margin-top: 30px;
                        .conversationSec {
                            gap: 15px;
                            align-items: center;
                        }
                        .conversationIcon {
                            width: 50px;
                            height: 50px;
                            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1019607843);
                            border-radius: 50%;
                            padding: 4px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            svg {
                                width: 40px;
                                border: 1.5px solid rgba(53, 212, 174, 0.2);
                                border-radius: 50%;
                                height: 40px;
                                object-fit: cover;
                                padding: 5px;
                            }
                        }
                        .conversationHeading {
                            h4 {
                                font-size: 16px;
                                font-weight: 700;
                                color: var(--light-black);
                                font-family: Montserrat;
                            }
                            p {
                                font-size: 14px;
                                color: var(--light-black);
                                margin: 0;
                                font-family: Montserrat;
                            }
                        }
                        .conversationPara {
                            font-size: 16px;
                            font-weight: 500;
                            margin-left: 0px;
                            margin-top: 10px;
                            word-break: break-word;
                            color: var(--darker-grey);
                        }
                    }
                }
            }
        }
    }
    .attachmentsSection {
        h5 {
            color: #4d4d4d;
            font-family: Montserrat;
            font-size: 20px;
            font-weight: 500;
        }
        .attachmentsImages {
            img {
                width: 50px;
                height: 50px;
                object-fit: contain;
                cursor: pointer;
            }
        }
    }
    .assessmentListSearchSec {
        z-index: 3 !important;
    }
    .filterClicked {
        z-index: 1 !important;
    }
    .scoreFilter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #d9d9d9;
        padding-bottom: 30px;
        margin-bottom: 30px;
        gap: 15px;
        div {
            width: 100%;
            text-align: center;
        }
        .breakSpace {
            width: 260px !important;
            margin-bottom: 6px;
        }
    }
    .CreatedAssessmentListTitle {
        h2 {
            @include fontStyle;
            font-size: 26px;
            font-weight: 600;
            margin: 45px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
    .assessmentListSearchSec {
        display: flex;
        align-items: center;
        gap: 20px;
        position: relative;
        z-index: 1;
        .templateSearchField {
            width: 100%;
        }
        .templateSearchField .templateSearchIconSec input.form-control {
            box-shadow: 0px 5px 25px 0px #c0c0c033;
            border: 2px solid #e9e9e9;
        }
    }
    .filterClicked {
        @include flexCss;
        background-color: #f9f9f9;
        padding: 30px 42px;
        gap: 50px;
        position: relative;
        top: -25px;
        z-index: 0;
        padding-top: 50px;
        width: calc(100% + 80px);
        left: -40px;
        .typeSelectSec {
            display: flex;
            gap: 21px;
            .skillAssessList {
                .customSelectType .selected-option {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 150px;
                }
                .searchSelectType .selected-option {
                    box-shadow: none;
                }
                .searchSelectType .selected-option input {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 150px;
                    &::placeholder {
                        color: black;
                    }
                }
            }
            .typeSelectFilter {
                flex: 0.5;
            }
            .typeSelectDateSec {
                flex: 2;
                display: flex;
                gap: 20px;
                .mainLabelFilter {
                    display: flex;
                    align-items: flex-end;
                    .arrowBtnSec {
                        display: flex;
                        align-items: center;
                        gap: 15px;
                        margin-bottom: 0px;
                        p {
                            margin: 0;
                            font-family: Montserrat;
                            font-size: 16px;
                            font-weight: 600;
                            color: #4d4d4d;
                        }
                        .parentFilterBtn {
                            flex: 1;
                        }
                        .scoreBtn {
                            padding: 8px 25px !important;
                            width: fit-content;
                            margin: 0;
                            background-color: #fff;
                            border: 1px solid #b3b3b3;
                            border-radius: 5px;
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
        .responseSecFilter {
            gap: 20px;
            margin-top: 30px;
        }
        .mainLabelFilter {
            @include fontStyle;
            font-size: 16px;
            font-weight: 500;
        }
        .radioCheckLabel {
            @include fontStyle;
            font-size: 16px;
            font-weight: 500;
            margin: 0;
            position: relative;
            top: 3px;
            cursor: pointer;
        }
        .manageClearAll {
            display: flex;
            justify-content: space-between;
        }
    }
    .addMargin {
        margin-bottom: 40px;
    }
    .AssessmentListContentSec {
        .AssessmentListContentTitle {
            @include fontStyle;
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 20px;
        }
        .AssessmentListCard {
            display: flex;
            border: 1px solid #efefef;
            margin-bottom: 20px;
            &:hover {
                box-shadow: 0 0 10px #35d4ae;
            }
            .AssessmentListCardImg {
                background-color: #edfffb;
                padding: 15px 30px;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                flex: 1;
                h6 {
                    @include fontStyle;
                    font-size: 14px;
                    font-weight: 500;
                }
                h4 {
                    @include fontStyle;
                    font-size: 20px;
                    font-weight: 700;
                    width: max-content;
                }
            }
            .AssessmentListCardDetails {
                width: 100%;
                padding: 0px 31px;
                flex: 6;
                .ListCardDetailsTitleSec {
                    @include flexCss;
                    gap: 79px;
                    .ListCardDetailsTitle {
                        width: 100%;
                        .bulletSec {
                            margin-bottom: 15px;
                        }
                    }
                }
                .ListCardDetailsContent {
                    display: flex;
                    align-items: center;
                    gap: 100px;
                    p {
                        margin-bottom: 5px;
                    }
                }
                .ListContentSkillsSec {
                    @include flexCss;
                    align-items: center;
                    gap: 79px;
                    margin-bottom: 10px;
                    .ListContentSkills {
                        display: flex;
                        gap: 100px;
                        width: 100%;
                        p {
                            margin-bottom: 5px;
                            font-weight: 500;
                        }
                    }
                    .decreaseGap {
                        gap: 78px;
                        h5 {
                            font-weight: 600;
                        }
                    }
                    .RightSideArrowSec {
                        margin-top: 35px;
                    }
                }
            }
        }
    }
}
.imagePreview {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 60%;
        height: 60%;
        object-fit: contain;
    }
}
@media(max-width:992px) {
    .supportTicketsCards {
        flex-direction: column;
    }
    .col-md-8 {
        width: 100%;
    }
    .col-md-4 {
        width: 100%;
    }
}
@media(max-width:450px) {
    .CreatedAssessmentListTitle {
        h2 {
            flex-direction: column;
            align-items: flex-start !important;
            gap: 10px;
        }
    }
}
@media(max-width:400px) {
    .viewTicketsSec .card .card-body .ticketBodyBg {
        padding: 0 !important;
    }
}