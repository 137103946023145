.profile-settings {
    position: relative;
    display: inline-block;
    cursor: pointer;
    .logo-place {
        cursor: pointer;
        align-items: center;
        background-color: var(--primary-blue);
        border-radius: 50% !important;
        color: var(--primary-white);
        display: flex;
        height: 40px !important;
        width: 40px !important;
        justify-content: center;
        font-family: Montserrat;
        font-size: 25px;
        font-weight: 700;
        text-transform: capitalize;
    }
    .usericon {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 12px;
        border: 1px solid var(--primary-green);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        background: var(--primary-white);
        background-color: var(--primary-white);
        border: 1px solid var(--primary-blue);
    }
}
.popover {
    position: absolute;
    top: 60px;
    right: 0;
    width: 300px;
    background-color: var(--primary-white);
    border: 1px solid var(--very-light-grey);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    border-radius: 0 !important;
    html[dir="rtl"] & {
        right: -245px;
    }
}
.popover-header {
    background-color: var(--very-light-green) !important;
    border-bottom: 1px solid var(--very-light-grey) !important;
    display: flex;
    flex-direction: column;
    padding: 16px !important;
    cursor: default;
    .logo-name-sec {
        display: flex;
    }
    .email-date-section {
        display: flex;
        gap: 6px;
        flex-direction: column;
        align-items: baseline;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        word-break: break-all;
        color: var(--darker-grey);
        .date-section {
            font-size: 10px;
        }
    }
}
.company-logo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 12px;
    border: 1px solid var(--primary-green);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    background: var(--primary-white);
}
.company-name {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    color: var(--light-black);
}
.popover-body {
    display: flex;
    flex-direction: column;
    // height: 260px;
    padding: 0px !important;
}
hr {
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
}
.popover-item {
    display: flex;
    align-items: center;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    gap: 15px;
    cursor: pointer;
    color: var(--darker-grey);
    padding: 10px 20px;
}
.popover-item:hover {
    background-color: var(--dark-white);
}
.accountDetailsDiv {
    display: flex;
    gap: 15px;
}