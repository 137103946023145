@mixin flexCss {
  display: flex;
  justify-content: space-between;
}
@mixin fontStyle {
  color: #4d4d4d;
  font-family: Montserrat;
  line-height: normal;
  font-style: normal;
}
.companyProfileWrapper {
  .backArrow {
    cursor: pointer;
  }
  .companyProfileCard {
    img {
      border-radius: 50%;
      box-shadow: 0px 0px 6px 5px #0000001A;
      width: 65px !important;
      height: 65px !important;
    }
    border: 0;
    // border-radius: 0%;
    min-height: 100vh;
    padding: 0 100px;
    // margin: 50px 0;
    .companyLogo {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .comapnyName {
      margin-left: 15px;
      font-family: Montserrat;
      font-size: 35px;
      font-weight: 600;
      color: #4d4d4d;
    }
    .detailContent {
      .detailContentHead {
        margin-top: 50px;
        margin-bottom: 33px;
        .detailContentHeadTile {
          display: flex;
          // justify-content: space-between;
          align-items: center;
          h5 {
            margin-left: 15px;
            font-family: Montserrat;
            font-size: 24px;
            font-weight: 600;
            color: #4d4d4d;
          }
        }
      }
      .detailContentValues {
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        gap: 14px;
        div {
          margin-top: 10px;
        }
        label {
          font-family: Montserrat;
          font-size: 12px;
          font-weight: 500;
          color: #797979;
        }
        h6 {
          font-family: Montserrat;
          font-size: 18px;
          font-weight: 500;
          color: #4d4d4d;
          text-wrap: wrap;
          width: 100%;
          a {
            text-decoration: none;
            font-family: Montserrat;
            font-size: 18px;
            font-weight: 500;
            color: #4d4d4d;
          }
        }
        .SocialLiks-icon {
          display: flex;
          gap: 20px;
          margin-top: 7px;
          svg {
            width: 26px;
            height: 26px;
          }
        }
      }
      .company-col {
        align-items: flex-start;
        flex-direction: column;
      }
    }
  }
  .pageTitle {
    h2 {
      @include fontStyle;
      font-size: 26px;
      font-weight: 600;
      margin: 45px 0;
    }
  }
}
@media(max-width:1100px) {
  .companyProfileWrapper .companyProfileCard {
    padding: 0 20px;
  }
}
@media(max-width:450px) {
  .companyProfileWrapper .companyProfileCard .comapnyName {
    font-size: 20px;
  }
  .companyProfileWrapper .companyProfileCard .detailContent .detailContentHead .detailContentHeadTile h5 {
    font-size: 18px;
  }
}