.dateRangePicker {
    margin-top: 40px;
    .dateLabel {
        display: flex;
        gap: 15px;
        // align-items: center;
        span {
            font-family: Montserrat;
            font-size: 16px;
            font-weight: 600;
            color: var(--light-black);
            margin-top: 10px;
        }
        input {
            border: 0;
            padding: 8px;
            border-radius: 3px;
            font-size: 16px;
            padding-right: 8px;
            background-color: transparent;
            cursor: pointer;
            padding: 10px;
            font-size: 14px;
            border-radius: 5px;
            box-shadow: 0px 5px 25px 0px rgba(192, 192, 192, 0.2);
            border: 1px solid #ccc;
            margin-right: 10px;
            &:focus {
                outline: none;
            }
        }
        .invalidDateError {
            color: red;
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 500;
            margin-top: 5px;
        }
    }
}