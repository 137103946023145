.table-container {
    width: 100%;
    overflow-x: auto;
    .tabel-header {
        background-color: #E7FFF9;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 18px;
        border-radius: 5px;
        margin-bottom: 10px;
        min-width: 900px;
    }
    .event-row {
        min-width: 900px;
        .event-item {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;
            background-color: #F9F9F9;
            padding: 18px;
            border-radius: 5px;
            .status-column {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .downArrow-icon {
                    cursor: pointer;
                    transition: transform 0.3s ease;
                }
                .rotate-arrow {
                    cursor: pointer;
                    transform: rotate(180deg);
                    transition: transform 0.3s ease;
                }
            }
        }
        .collapsable-details {
            font-family: Montserrat;
            color: #4D4D4D;
            border: 0.2px solid rgb(222, 219, 219);
            padding: 10px;
            margin-top: -12px;
            border-radius: 5px;
            .details-text {
                font-size: 14px;
                font-weight: 500;
            }
            .details-total-balance {
                font-size: 16px;
                font-weight: 600;
            }
            .detail-headers {
                margin-top: 10px;
                display: flex;
                font-size: 14px;
                font-weight: 500;
                color: #5A5A5A;
            }
            .detail-values {
                display: flex;
                font-family: Montserrat;
                font-size: 14px;
                font-weight: 600;
            }
        }
    }
}
@keyframes box {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.collapsable-details {
    animation-name: box;
    animation-duration: 1.5s;
}