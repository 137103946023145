.account-container {
    margin: 40px;
    .header-row {
        display: flex;
        justify-content: flex-end;
        font-family: Montserrat;
        color: #4d4d4d;
        font-family: Montserrat;
        line-height: normal;
        font-style: normal;
        font-size: 26px;
        font-weight: 600;
        margin-bottom: 0;
    }
    .search-bar {
        margin-top: 20px;
        .templateSearchField {
            .templateSearchIconSec {
                input.form-control {
                    box-shadow: 0px 5px 30px 0px #c0c0c033;
                    border: 2px solid #e9e9e9;
                }
            }
        }
        .accountsSidebar {
            display: flex;
            width: 100%;
            background-color: var(--bg-white);
            border-radius: 5px;
            margin-top: 20px;
            .sidebar-item {
                padding: 9px 10px;
                margin: 10px;
                border-radius: 5px;
                cursor: pointer;
                font-family: Montserrat;
                font-size: 18px;
                font-weight: 500;
                color: var(--darker-grey);
            }
            .sidebar-item:hover {
                background-color: var(--bg-white);
            }
            .sidebar-item.active {
                background-color: var(--active-green);
                font-weight: bold;
            }
        }
    }
    .accounts-table {
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }
    .categoryAccord {
        .accordion-item {
            border: 0;
            background-color: transparent !important;
            .accordion-button {
                background-color: transparent !important;
                font-size: 18px;
                font-weight: 600;
                color: #5a5a5a;
                padding-bottom: 20px;
                display: flex;
                justify-content: space-between;
                transition: color 0.3s ease;
                span {
                    svg {
                        width: 14px;
                        height: 14px;
                        transition: 0.5s all;
                        path {
                            fill: #4d4d4d;
                        }
                    }
                }
            }
            .accordion-button:not(.collapsed) {
                box-shadow: none;
                span {
                    svg {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
    .accordion-row {
        overflow: hidden;
        transition: max-height 0.3s ease;
        max-height: 0;
        background-color: #f9f9f9;
    }
    .accordion-row.show {
        max-height: 500px;
    }
    .accordion-body {
        padding: 0 1rem;
    }
}
@media(max-width:450px) {
    .account-container .header-row {
        flex-direction: column;
    }
}