.parentFilterBtn {
  cursor: pointer;
}
.scoreActive {
  border: 1px solid #35d4ae;
  background: #dffff7;
}
.scoreBtn {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  border: 1px solid #b3b3b3;
  background-color: #fff;
  border-radius: 5px;
  padding: 8px 20px;
  width: max-content;
  min-width: -webkit-fill-available;
}
@media (min-width: 1400px) and (max-width: 1500px) {
  .scoreBtn {
    padding: 8px 14px;
    font-size: 13px;
  }
}
