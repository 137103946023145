.CreateTicketPopup {
  .deleteData {
    // padding: 0 30px;
    .noListFound {
      height: auto;
      h3 {
        font-size: 16px;
        font-weight: 600;
        line-height: 28px;
        color: #5a5a5a;
        margin-top: 27px;
        margin-bottom: 40px;
      }
      .deleteBtnSec {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 40px;
        button {
          padding: 4px 30px !important;
          font-size: 16px !important;
          font-weight: 600 !important;
          line-height: 29px !important;
          color: #4d4d4d !important;
          border-radius: 3px !important;
        }
      }
    }
  }
  .popupCrossIcon {
    svg {
      position: absolute;
      top: 0;
      right: 1px;
    }
  }
}
@media (max-width: 992px) {
  .newTicketForm {
    box-shadow: none;
    padding: 0px;
  }
  .ticket-modal-section {
    .container-fluid {
      padding: 0;
    }
  }
  .instructionPopupSection .modal-dialog .modal-content {
    padding: 20px 10px;
  }
}